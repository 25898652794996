/* eslint-disable eqeqeq */
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Col, Input, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { TRANSFER_STATUS_OPTIONS } from "helpers/constans";
import { useEffect, useState } from "react";
import DropdownOption from "components/Common/DropdownOption";
import { toast } from "react-toastify";
import transferApi from "api/transferApi";
import { catchAsync } from "helpers/general";
import { CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import { saveAs } from "file-saver";
import { truncateFilename } from "utils";
import HistoriesChange from "./HistoriesChange";
import { useNavigate } from "react-router-dom";

interface Option {
  label: string;
  value: string;
}

function ModalDetail({ selectedRow, onCloseClick, refresh }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loadingComment, setLoadingComment] = useState<boolean>(false);
  const [isViewHistories, setIsViewHistories] = useState<boolean>(false);
  const TRANSFER_STATUS_OPTIONS_LANG = TRANSFER_STATUS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [comment, setComment] = useState<string>("");
  const [data, setData] = useState<any>();
  const [dropdownValue, setDropdownValue] = useState<any>();
  const [comments, setComments] = useState<any>();

  const changeStatus = async (id: number, status: number) => {
    if (!id) {
      // setLoadingStatus(false);
      return;
    }
    const [err, result] = await catchAsync(
      transferApi.updateStatus({
        id: id,
        body: {
          status: status,
        },
      })
      // setLoadingStatus
    );

    if (result?.statusCode === 200) {
      toast("success", CONFIG_OPTION_TOAST_NORMAL);
      setData(result?.data);
      setDropdownValue(
        TRANSFER_STATUS_OPTIONS_LANG.find((item) => item.value == result?.data?.status) || null
      );
    }
    if (err) {
      toast.error(err);
      return;
    }
  };

  const writeComment = async () => {
    if (!data?.id) {
      setLoadingComment(false);
      return;
    }
    const [err, result] = await catchAsync(
      transferApi.addComment({
        id: data?.id,
        body: {
          content: comment,
        },
      }),
      setLoadingComment
    );

    if (result?.statusCode === 200) {
      toast("success", CONFIG_OPTION_TOAST_NORMAL);
      // refresh();
      setComments(result?.data);
      setComment("");
    }

    if (err) {
      toast.error(err);
      return;
    }
  };

  const leftData = [
    {
      label: t("transfer.request_date"),
      value: moment(data?.created_at).isValid()
        ? moment(data?.created_at).format("YYYY.MM.DD HH:mm:ss")
        : "-",
    },
    {
      label: t("transfer.table.media_name"),
      value: data?.company_name ? data?.company_name : "-",
    },
    {
      label: t("transfer.table.contact_name"),
      value: data?.contact_name ? data?.contact_name : "-",
    },
    {
      label: t("transfer.phone_number"),
      value: data?.phone_number ? data?.phone_number : "-",
    },
    {
      label: t("transfer.table.email"),
      value: data?.email ? data?.email : "-",
    },
    {
      label: t("transfer.service_in_use"),
      value: data?.transfer_service_name
        ? data?.transfer_service_id == 6
          ? `${t("transfer.other")} | ${data?.transfer_service_name}`
          : data?.transfer_service_name
        : "-",
    },
  ];

  const rightData = [
    {
      label: t("transfer.table.status"),
      value: (
        <DropdownOption
          name="status"
          dataList={TRANSFER_STATUS_OPTIONS_LANG.filter((item) => item.value != 0) || []}
          className="search-filter-category-type"
          classNamePrefix="name-prefix"
          initialValue={dropdownValue}
          onChangeSelect={(value: Option) => {
            changeStatus(data.id, Number(value?.value));
          }}
        />
      ),
    },
    {
      label: t("transfer.table.update_date"),
      value: moment(data?.updated_at).isValid()
        ? moment(data?.updated_at).format("YYYY.MM.DD HH:mm:ss")
        : "-",
    },
    {
      label: t("transfer.table.sales_representative"),
      value: data?.managed_by?.name ? data?.managed_by?.name : "-",
    },
    {
      label: t("transfer.change_history"),
      value: (
        <p
          onClick={() => setIsViewHistories(true)}
          className="text-decoration-underline cursor-pointer mb-0"
        >
          {t("transfer.view_details")}
        </p>
      ),
    },
  ];

  useEffect(() => {
    setData(selectedRow);
    setDropdownValue(
      TRANSFER_STATUS_OPTIONS_LANG.find((item) => item.value == selectedRow?.status) || null
    );
    setComments(selectedRow?.comments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  return (
    <>
      <Modal
        isOpen={!!data?.id}
        centered={true}
        scrollable={true}
        style={{ maxWidth: "1000px", width: "100%" }}
      >
        <ModalBody
          className=""
          style={{
            paddingTop: "0",
          }}
        >
          {isViewHistories && (
            <HistoriesChange
              open={isViewHistories}
              id={data?.id}
              onClose={() => setIsViewHistories(false)}
            />
          )}

          <div>
            <div
              className="d-relative"
              style={{
                position: "sticky",
                top: "0",
                background: "white",
                paddingTop: "20px",
                zIndex: 1,
              }}
            >
              <h5 className="text-center w-100 mb-4">
                {t("transfer.menu_title")} | {t("transfer.detail")}
              </h5>
              <div
                className="position-absolute cursor-pointer"
                style={{
                  top: "12px",
                  right: "0px",
                }}
              >
                <button
                  onClick={onCloseClick}
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                ></button>
              </div>
            </div>
            <div className="body">
              <div className="row">
                <div className="col-6">
                  {leftData?.map(({ label, value }: any) => (
                    <div key={label} className="row my-2">
                      <div className="col-5">{label}</div>
                      <div className="col-4">{value}</div>
                    </div>
                  ))}
                </div>
                <div className="col-6">
                  {rightData?.map(({ label, value }: any) => (
                    <div key={label} className="row my-2 flex align-items-center">
                      <div className="col-4">{label}</div>
                      <div className="col-6">{value}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row my-3">
                <div className="col-6">
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5 fw-bold">{t("transfer.FTP_server")}</div>
                    <div className="col-4">{""}</div>
                  </div>
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5">{t("transfer.IP_address")}</div>
                    <div className="col-4">{data?.transfer_data_info?.ip_address}</div>
                  </div>
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5">{t("transfer.port_number")}</div>
                    <div className="col-4">{data?.transfer_data_info?.port_number}</div>
                  </div>
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5">{t("transfer.FTP_ID")}</div>
                    <div className="col-4">{data?.transfer_data_info?.ftp_id}</div>
                  </div>
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5">{t("transfer.FTP_password")}</div>
                    <div className="col-4">{data?.transfer_data_info?.password}</div>
                  </div>
                </div>
                <div className="col-6"></div>
                <div className="col-6 my-4">
                  <div className="row flex align-items-center ">
                    <div className="col-3 fw-bold">{t("transfer.file_upload")}</div>
                    <div className="col-4">
                      <button
                        type="button"
                        className="btn btn-secondary fs-14"
                        style={{
                          minWidth: "150px",
                          width: "fix-content",
                        }}
                        onClick={() => navigate("/import")}
                      >
                        <p className="mb-0" style={{ width: "max-content" }}>
                          {data?.status == TRANSFER_STATUS_OPTIONS[1]?.value
                            ? t("transfer.migration_progress")
                            : TRANSFER_STATUS_OPTIONS_LANG.find(
                                (item) => item.value == data?.status
                              )?.label}
                        </p>
                      </button>
                    </div>
                  </div>
                  {data?.transfer_data_info?.files?.map((file: any, index: number) => (
                    <div key={index} className="row my-2">
                      <div className="col-5 text-truncate">{truncateFilename(file?.name, 15)}</div>
                      <div className="col-4">
                        {file.size > 10 * 1024 * 1024 ? (
                          <a href={file?.url} download={true}>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="mb-2 cursor-pointer"
                            >
                              <g clipPath="url(#clip0_4983_3094)">
                                <path
                                  d="M6.58553 12.0813C6.77126 12.2672 6.99179 12.4146 7.23453 12.5152C7.47726 12.6158 7.73744 12.6676 8.00019 12.6676C8.26294 12.6676 8.52312 12.6158 8.76585 12.5152C9.00859 12.4146 9.22912 12.2672 9.41486 12.0813L11.5555 9.94067C11.6703 9.81373 11.7319 9.64752 11.7275 9.47644C11.7231 9.30536 11.6531 9.14253 11.5319 9.02165C11.4108 8.90077 11.2478 8.8311 11.0767 8.82707C10.9056 8.82304 10.7396 8.88496 10.6129 9L8.66219 10.9513L8.66686 0.666667C8.66686 0.489856 8.59662 0.320286 8.4716 0.195262C8.34657 0.0702379 8.177 0 8.00019 0V0C7.82338 0 7.65381 0.0702379 7.52879 0.195262C7.40376 0.320286 7.33352 0.489856 7.33352 0.666667L7.32752 10.9387L5.38753 9C5.26243 8.875 5.0928 8.8048 4.91596 8.80486C4.73911 8.80493 4.56953 8.87524 4.44452 9.00033C4.31952 9.12543 4.24933 9.29506 4.24939 9.4719C4.24945 9.64875 4.31976 9.81833 4.44486 9.94333L6.58553 12.0813Z"
                                  fill="black"
                                />
                                <path
                                  d="M15.3333 10.667C15.1565 10.667 14.987 10.7372 14.8619 10.8623C14.7369 10.9873 14.6667 11.1568 14.6667 11.3337V14.0003C14.6667 14.1771 14.5964 14.3467 14.4714 14.4717C14.3464 14.5967 14.1768 14.667 14 14.667H2C1.82319 14.667 1.65362 14.5967 1.5286 14.4717C1.40357 14.3467 1.33333 14.1771 1.33333 14.0003V11.3337C1.33333 11.1568 1.2631 10.9873 1.13807 10.8623C1.01305 10.7372 0.843478 10.667 0.666667 10.667C0.489856 10.667 0.320286 10.7372 0.195262 10.8623C0.0702379 10.9873 0 11.1568 0 11.3337L0 14.0003C0 14.5308 0.210714 15.0395 0.585786 15.4145C0.960859 15.7896 1.46957 16.0003 2 16.0003H14C14.5304 16.0003 15.0391 15.7896 15.4142 15.4145C15.7893 15.0395 16 14.5308 16 14.0003V11.3337C16 11.1568 15.9298 10.9873 15.8047 10.8623C15.6797 10.7372 15.5101 10.667 15.3333 10.667Z"
                                  fill="black"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4983_3094">
                                  <rect width="16" height="16" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        ) : (
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mb-2 cursor-pointer"
                            onClick={() => saveAs(file?.url, file?.name)}
                          >
                            <g clipPath="url(#clip0_4983_3094)">
                              <path
                                d="M6.58553 12.0813C6.77126 12.2672 6.99179 12.4146 7.23453 12.5152C7.47726 12.6158 7.73744 12.6676 8.00019 12.6676C8.26294 12.6676 8.52312 12.6158 8.76585 12.5152C9.00859 12.4146 9.22912 12.2672 9.41486 12.0813L11.5555 9.94067C11.6703 9.81373 11.7319 9.64752 11.7275 9.47644C11.7231 9.30536 11.6531 9.14253 11.5319 9.02165C11.4108 8.90077 11.2478 8.8311 11.0767 8.82707C10.9056 8.82304 10.7396 8.88496 10.6129 9L8.66219 10.9513L8.66686 0.666667C8.66686 0.489856 8.59662 0.320286 8.4716 0.195262C8.34657 0.0702379 8.177 0 8.00019 0V0C7.82338 0 7.65381 0.0702379 7.52879 0.195262C7.40376 0.320286 7.33352 0.489856 7.33352 0.666667L7.32752 10.9387L5.38753 9C5.26243 8.875 5.0928 8.8048 4.91596 8.80486C4.73911 8.80493 4.56953 8.87524 4.44452 9.00033C4.31952 9.12543 4.24933 9.29506 4.24939 9.4719C4.24945 9.64875 4.31976 9.81833 4.44486 9.94333L6.58553 12.0813Z"
                                fill="black"
                              />
                              <path
                                d="M15.3333 10.667C15.1565 10.667 14.987 10.7372 14.8619 10.8623C14.7369 10.9873 14.6667 11.1568 14.6667 11.3337V14.0003C14.6667 14.1771 14.5964 14.3467 14.4714 14.4717C14.3464 14.5967 14.1768 14.667 14 14.667H2C1.82319 14.667 1.65362 14.5967 1.5286 14.4717C1.40357 14.3467 1.33333 14.1771 1.33333 14.0003V11.3337C1.33333 11.1568 1.2631 10.9873 1.13807 10.8623C1.01305 10.7372 0.843478 10.667 0.666667 10.667C0.489856 10.667 0.320286 10.7372 0.195262 10.8623C0.0702379 10.9873 0 11.1568 0 11.3337L0 14.0003C0 14.5308 0.210714 15.0395 0.585786 15.4145C0.960859 15.7896 1.46957 16.0003 2 16.0003H14C14.5304 16.0003 15.0391 15.7896 15.4142 15.4145C15.7893 15.0395 16 14.5308 16 14.0003V11.3337C16 11.1568 15.9298 10.9873 15.8047 10.8623C15.6797 10.7372 15.5101 10.667 15.3333 10.667Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4983_3094">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-6"></div>

                <div className="col-6">
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5 fw-bold">{t("transfer.domain")}</div>
                    <div className="col-4">{""}</div>
                  </div>
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5">{t("transfer.domain_provider")}</div>
                    <div className="col-4">{data?.domain_provider}</div>
                  </div>
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5">{t("transfer.domain_id")}</div>
                    <div className="col-4">{data?.domain_id}</div>
                  </div>
                  <div className="row my-2 flex align-items-center">
                    <div className="col-5">{t("transfer.domain_password")}</div>
                    <div className="col-4">{data?.domain_password}</div>
                  </div>
                </div>
              </div>
              <div className="my-3">
                <div className="fw-bold">{t("transfer.request_content")}</div>
                <div className="" style={{ minHeight: "24px" }}>
                  {data?.note}
                </div>
              </div>
              <div className="my-3">
                <div>
                  <div className="fw-bold">{t("transfer.memo")}</div>
                  <Row className="g-4 align-items-center mt-0">
                    <Col sm={6} md={6} lg={6} className="mt-3 mt-md-2 mb-2">
                      <div className="d-flex align-items-center ">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("transfer.comment_placeholder")}`}
                          value={comment}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              writeComment();
                            }
                          }}
                          maxLength={100}
                        />
                      </div>
                    </Col>
                    <Col sm={6} md={6} lg={6} className="mt-3 mt-md-2 px-0 mb-2">
                      <button
                        type="button"
                        className="btn btn-secondary fs-14"
                        style={{
                          minWidth: "100px",
                        }}
                        onClick={writeComment}
                      >
                        {loadingComment && (
                          <Spinner size="sm" className="me-2">
                            {t("Loading")}
                          </Spinner>
                        )}
                        <span>{t("transfer.register")}</span>
                      </button>
                    </Col>
                    {comments?.map((item: any, index: number) => (
                      <>
                        <Col sm={2} md={2} lg={2} className="my-1">
                          {moment(item?.created_at).isValid()
                            ? moment(item?.created_at).format("YYYY.MM.DD HH:mm:ss")
                            : "-"}
                        </Col>
                        <Col sm={5} md={5} lg={5} className="my-1">
                          <p className="text-truncate my-0">{item?.content}</p>
                        </Col>
                        <Col sm={5} md={5} lg={5} className="my-1">
                          <p className="text-truncate my-0">{item?.managed_by?.name}</p>
                        </Col>
                      </>
                    ))}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ModalDetail;
