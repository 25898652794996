import inquiryApi from "api/inquiryApi";
import DropdownOption from "components/Common/DropdownOption";
import { useFormik } from "formik";
import {
  INQUIRY_TYPE_OPTIONS,
  INQUIRY_VIA_PHONE_OPTIONS,
} from "helpers/constans";
import { catchAsync } from "helpers/general";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FormFeedback, Input, Modal, ModalBody, Spinner } from "reactstrap";
import * as Yup from "yup";

interface Props {
  open: boolean;
  data?: any;
  onClose: () => void;
  refresh: (data?: any) => void;
}
const ModalRecordRegistration = ({ open, onClose, refresh, data }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    status: data?.status ? data?.status + "" : "1",
    inquiry_type: data?.inquiry_type ? data?.inquiry_type + "" : "1",
    company_name: data?.company_name ? data?.company_name + "" : "",
    contact_name: data?.contact_name ? data?.contact_name + "" : "",
    email: data?.email ? data?.email + "" : "",
    phone_number: data?.phone_number ? data?.phone_number + "" : "",
    content: data?.content ? data?.content + "" : "",
  };

  const createPopupSchema = Yup.object().shape({
    company_name: Yup.string().required(),
    contact_name: Yup.string().required(),
  });

  const removeEmptyFields = (obj: any): any => {
    const result: any = {};

    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          const nestedObject = removeEmptyFields(obj[key]);
          if (Object.keys(nestedObject).length > 0) {
            result[key] = nestedObject;
          }
        } else if (Array.isArray(obj[key])) {
          const filteredArray = obj[key]
            .map((item: any) =>
              typeof item === "object" ? removeEmptyFields(item) : item
            )
            .filter((item: any) => item !== null && item !== undefined);

          if (filteredArray.length > 0) {
            result[key] = filteredArray;
          }
        } else {
          result[key] = obj[key];
        }
      }
    }

    return result;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createPopupSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const params = {
        company_name: values?.company_name?.trim(),
        contact_name: values?.contact_name?.trim(),
        email: values?.email?.trim(),
        phone_number: values?.phone_number?.trim(),
        content: values?.content?.trim(),
        inquiry_type: +values?.inquiry_type,
        status: +values?.status,
      } as any;
      const [error, result] = await catchAsync(
        data?.id
          ? inquiryApi.updateInquiriesPhone(data?.id, params)
          : inquiryApi.createInquiriesPhone(params),
        setLoading
      );
      if (error) {
        typeof error === "string"
          ? toast.error(error)
          : formik.setErrors(error);
        return;
      }

      if (data?.id) {
        toast.success(t("inquiry.edit_toast_message"), {
          position: "top-center",
        });
      } else {
        toast.success(t("inquiry.create_toast_message"), {
          position: "top-center",
        });
      }

      refresh(result?.data);
      return;
    },
  });

  const STATUS_OPTIONS = INQUIRY_VIA_PHONE_OPTIONS.slice(1)
    .filter((i) => !!i.value)
    ?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    }));

  const TYPE_OPTIONS = INQUIRY_TYPE_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      centered={true}
      scrollable={false}
      style={{ minWidth: "544px" }}
    >
      <div className="d-flex justify-content-center pt-3 pb-2 px-3 items-center">
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {t("inquiry.record_registration")}
        </div>
      </div>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column gap-3">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  {t("inquiry.Status")}
                </label>
                <div className="col-sm-9">
                  <DropdownOption
                    name="status"
                    dataList={STATUS_OPTIONS}
                    className="search-filter-category-type"
                    classNamePrefix="name-prefix"
                    initialValue={STATUS_OPTIONS?.find(
                      (i) => i?.value == formik?.values?.status
                    )}
                    onChangeSelect={(e: any) => {
                      if (e) {
                        formik.setFieldValue("status", Number(e?.value));
                      }
                    }}
                    labelTop={""}
                  />
                  <FormFeedback>
                    {formik.values.status ? formik.errors.status : ""}
                  </FormFeedback>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  {t("inquiry.Inquiry_type")}
                </label>
                <div className="col-sm-9">
                  <DropdownOption
                    name="inquiry_type"
                    dataList={TYPE_OPTIONS}
                    className="search-filter-category-type"
                    classNamePrefix="name-prefix"
                    initialValue={TYPE_OPTIONS?.find(
                      (i) => i?.value == formik?.values?.inquiry_type
                    )}
                    onChangeSelect={(e: any) => {
                      if (e) {
                        formik.setFieldValue("inquiry_type", Number(e?.value));
                      }
                    }}
                    labelTop={""}
                  />
                  <FormFeedback>
                    {formik.values.inquiry_type
                      ? formik.errors.inquiry_type
                      : ""}
                  </FormFeedback>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  {t("inquiry.media_name")}
                  <span className="text-danger"> *</span>
                </label>
                <div className="col-sm-9">
                  <Input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps("company_name")}
                    placeholder={t("inquiry.placeholder")}
                    maxLength={50}
                    invalid={
                      !!(
                        formik.values.company_name && formik.errors.company_name
                      )
                    }
                  />
                  <FormFeedback>
                    {formik.values.company_name
                      ? formik.errors.company_name
                      : ""}
                  </FormFeedback>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  {t("inquiry.contact_person")}
                  <span className="text-danger"> *</span>
                </label>
                <div className="col-sm-9">
                  <Input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps("contact_name")}
                    placeholder={t("inquiry.placeholder")}
                    maxLength={50}
                    invalid={
                      !!(
                        formik.values.contact_name && formik.errors.contact_name
                      )
                    }
                  />
                  <FormFeedback>
                    {formik.values.contact_name
                      ? formik.errors.contact_name
                      : ""}
                  </FormFeedback>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  {t("inquiry.email")}
                </label>
                <div className="col-sm-9">
                  <Input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps("email")}
                    placeholder={t("inquiry.placeholder")}
                    maxLength={50}
                    invalid={!!(formik.values.email && formik.errors.email)}
                  />
                  <FormFeedback>
                    {formik.values.email ? formik.errors.email : ""}
                  </FormFeedback>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  {t("inquiry.phoneNumber")}
                </label>
                <div className="col-sm-9">
                  <Input
                    type="text"
                    className="form-control"
                    {...formik.getFieldProps("phone_number")}
                    placeholder={t("inquiry.placeholder")}
                    maxLength={50}
                    invalid={
                      !!(
                        formik.values.phone_number && formik.errors.phone_number
                      )
                    }
                  />
                  <FormFeedback>
                    {formik.values.phone_number
                      ? formik.errors.phone_number
                      : ""}
                  </FormFeedback>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  {t("inquiry.Memo")}
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    id="textMemo"
                    rows={5}
                    placeholder={t("inquiry.placeholder")}
                    {...formik.getFieldProps("content")}
                    maxLength={500}
                  />
                </div>
              </div>
            </div>

            <div className="hstack gap-2 mt-3 justify-content-center">
              <button
                className="btn btn-soft-secondary fs-14"
                type="button"
                color="light"
                onClick={() => onClose()}
                style={{ width: "150px" }}
              >
                {t("cms_media.cancellation")}
              </button>
              <button
                type="submit"
                className="btn btn-primary fs-14"
                style={{ width: "150px" }}
                disabled={
                  !formik.isValid ||
                  !formik.dirty ||
                  !formik?.values?.company_name?.trim() ||
                  !formik?.values?.contact_name?.trim()
                }
              >
                {loading ? <Spinner size="sm me-2"></Spinner> : null}
                {t("cms_media.registration")}
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalRecordRegistration;
