import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";
//
import "react-toastify/dist/ReactToastify.css";
import useQueryParams from "components/Hooks/useQueryParams";
import TableContainer from "../../../components/Common/TableContainer";
import { catchAsync, formatNumber } from "helpers/general";
import cmsHubMediaApi from "api/cmsHubMediaApi";
import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import {
  IresHomepageCmsHub,
  IresSumaryHomepageCmsHub,
} from "api/types/_cmsHub";
import ButtonMediaAccess from "../Components/ButtonGotoMedia";
import templateImage from "../../../assets/images/cmshub/template.png";
import sectionImage from "../../../assets/images/cmshub/section.png";

const HomePage = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 30,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;

  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<IresHomepageCmsHub>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [summary, setSummary] = useState({} as IresSumaryHomepageCmsHub);

  // Column
  const columns = useMemo(() => {
    const renderLayout = (original: IresHomepageCmsHub) => (
      <div>{original?.template?.name}</div>
    );

    return [
      {
        Header: t("cms_media.number"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => <div> {pagination.total - pagination.page_size * (pagination.page - 1) - (row?.index || 0)}</div>,
      },
      {
        Header: t("cms_media.media_name"),
        accessor: "name",
        filterable: false,
        sortable: false,
        thWidth: "200px",
        Cell: ({ row }: any) => (
          <div className="d-flex gap-2 align-items-center">
            {row.original?.company?.name}
          </div>
        ),
      },
      {
        Header: "",
        accessor: "goto_media",
        filterable: false,
        sortable: false,
        thWidth: "150px",
        Cell: ({ row }: any) => (
          <ButtonMediaAccess id={row?.original?.company_id} hasMainAdmin={row?.original?.has_main_admin} redirect="/app/bulletin-board-management"/>
        ),
      },
      {
        Header: t("cms_media.main_section"),
        accessor: "main_section_count",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatNumber(row?.original?.main_section_count || 0)}
          </div>
        ),
      },
      {
        Header: t("cms_media.subsection"),
        accessor: "sub_section_count",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatNumber(row?.original?.sub_section_count || 0)}
          </div>
        ),
      },
      {
        Header: t("cms_media.layout"),
        accessor: "template",
        filterable: false,
        sortable: false,
        Cell: ({ row }: any) => renderLayout(row.original),
      },
      {
        Header: t("cms_media.template_a"),
        accessor: "sub_template_q_count",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatNumber(row?.original?.sub_template_q_count || 0)}
          </div>
        ),
      },
      {
        Header: t("cms_media.template_b"),
        accessor: "sub_template_w_count",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatNumber(row?.original?.sub_template_w_count || 0)}
          </div>
        ),
      },
      {
        Header: t("cms_media.pop_up_exposure"),
        accessor: "pop_up_exposure",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: ({ row }: any) => (
          <div className="text-center">
            {formatNumber(row?.original?.company?.popup_count || 0)}
          </div>
        ),
      },
    ];
  }, [t, i18n?.language,pagination]);

  const paging = async () => {
    queryParams.start_date = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    queryParams.end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    const [err, result] = await catchAsync(
      cmsHubMediaApi.cmshubHomepage(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const sumary = async () => {
    queryParams.start_date = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    queryParams.end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    const [err, result] = await catchAsync(
      cmsHubMediaApi.cmshubHomepageSumary(queryParams)
    );
    if (err) {
      console.log(err);
      return;
    }
    setSummary(result?.data);
  };

  // Search
  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      end_date:endDate ? moment(endDate).format("YYYY-MM-DD") : null
    });
  };

  const handleChangePage = useCallback((page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
  }, []);

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    paging();
    sumary();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <Card id="leadsList">
        <CardHeader className="border-0">
          <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
            <div className="w-100">
              <Row>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-2 mt-md-0 mb-2 mb-md-0"
                >
                  <div
                    className="d-flex align-items-center date-picker-wrapper-custom"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">{t('cms_media.period')}</div>
                    <div style={{ width: "300px" }}>
                      <DatePickerCustom
                        placeholder={`${t("A From Range")} - ${t(
                          "A To Range"
                        )}`}
                        startDate={startDate || null}
                        endDate={endDate || null}
                        disabled={false}
                        isClearable={true}
                        onChangePicker={handleChangePicker}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="g-4 align-items-center mt-0">
                <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "40px" }}
                  >
                    <div className="fw-bold text-nowrap">{t('cms_media.search')}</div>
                    <Input
                      type="text"
                      className="form-control search"
                      placeholder={`${t("cms_media.input_search_hompage")}...`}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchCategory();
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                >
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={loading}
                      onClick={handleSearchCategory}
                    >
                      <i className="ri-search-line align-bottom me-1"></i>{" "}
                      {t("Button Search")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary fs-14"
                      onClick={resetData}
                    >
                      <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                      {t("Button Reset")}
                    </button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2 mt-md-3">
                <Col sm={12} md={12} lg={6} xxl={3} className="h-100">
                  <Card className="p-3" style={{ height: "210px"}}>
                    <div className="fw-bold">
                      {t("cms_media.number_of_layouts_used")}
                    </div>
                    <div style={{ display:'flex',gap:'10px',justifyContent:'center',marginTop:'40px',maxHeight:'calc(100% - 100px)' }}>
                      {
                        summary?.layout?.map((item) => (
                          <div className="w-100"><img
                            src={item.template?.skeleton_image}
                            alt=""
                            style={{ height: 'auto',
                            width: "100%",objectFit: "cover",maxHeight:'100%' }}/></div>
                        ))
                      }
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-around" }}
                    >
                      {summary?.layout?.map((item) => (
                        <div>
                          <div className="fw-bold">{item.template?.name}</div>
                          <div className="text-center">{item.count}</div>
                        </div>
                      ))}
                    </div>
                  </Card>
                </Col>
                <Col sm={12} md={12} lg={6} xxl={3} className="h-100">
                  <Card className="p-3" style={{ height: "210px" }}>
                    <div className="fw-bold">
                      {t("cms_media.number_of_templates_used")}
                    </div>
                    <div style={{ display:'flex',gap:'10px',justifyContent:'center',marginTop:'40px',maxHeight:'calc(100% - 90px)' }}>
                      {
                        summary?.subtemplate?.map((item) => (
                          <div className="w-100"><img
                          src={item.section?.skeleton_image}
                          alt=""
                          style={{ 
                            height: 'auto',
                            width: "100%",objectFit: "cover",maxHeight:'57px'
                          }}/></div>
                        ))
                      }
                    </div>
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-around",
                        marginTop: "17px",
                      }}
                    >
                       {summary?.subtemplate?.map((item) => (
                        <div>
                          <div className="fw-bold">{item.section?.name}</div>
                          <div className="text-center">{item.count}</div>
                        </div>
                      ))}
                    </div>
                  </Card>
                </Col>
                <Col sm={12} md={12} lg={6} xxl={3} className="h-100">
                  <Card className="p-3" style={{ height: "210px" }}>
                    <div className="fw-bold">
                      {t("cms_media.article_exposure_settings")}
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{ paddingTop: "12px" }}
                    >
                      <div className="text-center d-flex flex-column gap-2">
                        <div className="fw-bold">{t("cms_media.division")}</div>
                        <div>{t("cms_media.headline")}</div>
                        <div>{t("cms_media.important_article")}</div>
                        <div>{t("cms_media.latest_article")}</div>
                        <div>{t("cms_media.popular_article")}</div>
                      </div>
                      <div className="text-center d-flex flex-column gap-2">
                        <div className="fw-bold" style={{whiteSpace:'nowrap'}}>
                          {t("cms_media.direct_settings")}
                        </div>
                        <div>
                          {formatNumber(
                            summary.setting_summary?.headline?.manual || 0
                          )}
                        </div>
                        <div>
                          {formatNumber(
                            summary.setting_summary?.important?.manual || 0
                          )}
                        </div>
                        <div>
                          {formatNumber(
                            summary.setting_summary?.latest?.manual || 0
                          )}
                        </div>
                        <div>
                          {formatNumber(
                            summary.setting_summary?.popular?.manual || 0
                          )}
                        </div>
                      </div>
                      <div className="text-center d-flex flex-column gap-2">
                        <div className="fw-bold">
                          {t("cms_media.automatic_setting")}
                        </div>
                        <div>
                          {formatNumber(
                            summary.setting_summary?.headline?.auto || 0
                          )}
                        </div>
                        <div>
                          {formatNumber(
                            summary.setting_summary?.important?.auto || 0
                          )}
                        </div>
                        <div>
                          {formatNumber(
                            summary.setting_summary?.latest?.auto || 0
                          )}
                        </div>
                        <div>
                          {formatNumber(
                            summary.setting_summary?.popular?.auto || 0
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col sm={12} md={12} lg={6} xxl={3} className="h-100">
                  <Card className="p-3" style={{ height: "210px" }}>
                    <div className="fw-bold">{t("cms_media.update_time")}</div>
                    <div
                      className="align-items-center"
                      style={{ paddingTop: "10px", display:'grid', gridTemplateColumns: "auto auto",columnGap:'10%' }}
                    >
                      <div
                        className="d-flex justify-content-between"
                      >
                        <div className="text-center d-flex flex-column gap-2" style={{whiteSpace:'nowrap',}}>
                          <div className="fw-bold">
                            {t("cms_media.division")}
                          </div>
                          <div>{t("cms_media.headline")}</div>
                          <div>{t("cms_media.important_article")}</div>
                          <div>{t("cms_media.latest_article")}</div>
                          <div>{t("cms_media.popular_article")}</div>
                        </div>
                        <div className="text-center d-flex flex-column gap-2">
                          <div className="fw-bold">
                            {t("cms_media.real_time")}
                          </div>
                          <div>
                            {formatNumber(
                              summary.update_time_summary?.headline?.auto || 0
                            )}
                          </div>
                          <div>
                            {formatNumber(
                              summary.update_time_summary?.important?.auto || 0
                            )}
                          </div>
                          <div>
                            {formatNumber(
                              summary.update_time_summary?.latest?.auto || 0
                            )}
                          </div>
                          <div>
                            {formatNumber(
                              summary.update_time_summary?.popular?.auto || 0
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{
                          background: "#E5E5E5",
                          borderRadius: "10px",
                          padding: "2px 10px",
                        }}
                      >
                        <div className="text-center d-flex flex-column gap-2">
                          <div className="tooltip-container">
                            <div className="fw-bold line-clamp-1">
                              {t("cms_media.direct_settings")}
                            </div>
                            <div className="tooltiptext">
                              {t("cms_media.direct_settings")}
                            </div>
                          </div>
                          <div>
                            {formatNumber(
                              summary.update_time_summary?.headline?.manual || 0
                            )}
                          </div>
                          <div>
                            {formatNumber(
                              summary.update_time_summary?.important?.manual ||
                                0
                            )}
                          </div>
                          <div>
                            {formatNumber(
                              summary.update_time_summary?.latest?.manual || 0
                            )}
                          </div>
                          <div>
                            {formatNumber(
                              summary.update_time_summary?.popular?.manual || 0
                            )}
                          </div>
                        </div>
                        <div className="text-center d-flex flex-column gap-2">
                          <div className="tooltip-container">
                            <div className="fw-bold line-clamp-1">
                            {t("cms_media.most_selected_time")}
                            </div>
                            <div className="tooltiptext">
                              {t("cms_media.most_selected_time")}
                            </div>
                          </div>
                          <div>
                            {summary.update_time_summary?.headline
                              ?.most_selected_time || "-"}
                          </div>
                          <div>
                            {summary.update_time_summary?.important
                              ?.most_selected_time || "-"}
                          </div>
                          <div>
                            {summary.update_time_summary?.latest
                              ?.most_selected_time || "-"}
                          </div>
                          <div>
                            {summary.update_time_summary?.popular
                              ?.most_selected_time || "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-3">
          <div>
            <TableContainer
              columns={columns}
              data={rows || []}
              customPageSize={pagination?.page_size}
              customPageIndex={pagination?.page - 1}
              totalRecords={pagination?.total}
              customPageCount={Math.ceil(
                pagination?.total / pagination?.page_size
              )}
              className="custom-header-css"
              divClass="table-responsive table-card"
              tableClass="align-middle"
              theadClass="table-light"
              handleChangePage={handleChangePage}
              isLoading={loading}
            />
          </div>
          <ToastContainer closeButton={false} limit={1} />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default HomePage;
