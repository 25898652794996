import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
//
import MediaApi from "api/mediaApi";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import { catchAsync } from "helpers/general";
import warningSvg from "../../../assets/images/media/warning.svg";
import { useModal } from "context/confirm.context";
import { LAYOUT_TYPE } from "helpers/constans";

const SelectAuthority = ({
  options,
  value,
  onChange,
  id,
  is_max_number_admin,
  disabled,
  limit_admin,
  is_max_number_desk_reporter
}: any) => {
  const handleChangeOption = (e: any) => () => {
    onChange(e?.value, e?.label);
    // setOption(e?.value);
  };

  const handleClickOutside = (event: any) => {
    const selectAuthority = document.getElementById(`selectAuthority${id}`);
    if (selectAuthority?.contains(event.target)) {
      selectAuthority?.classList?.add("show");
    } else {
      selectAuthority?.classList?.remove("show");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      id={`selectAuthority${id}`}
      className={`selectAuthority position-relative border rounded`}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
        background: disabled ? "hsl(0, 0%, 95%)" : "white",
      }}
    >
      <div className="d-flex align-items-center px-2 py-1">
        <p className="m-0">
          {options?.find((item: any) => item?.value === value)?.label}
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
        >
          <path
            d="M11.8075 5.29286C11.7533 5.23828 11.6889 5.19495 11.618 5.16539C11.547 5.13583 11.4709 5.12061 11.394 5.12061C11.3172 5.12061 11.241 5.13583 11.1701 5.16539C11.0991 5.19495 11.0347 5.23828 10.9806 5.29286L8.31349 7.95994C8.25935 8.01452 8.19495 8.05784 8.12398 8.0874C8.05302 8.11697 7.97691 8.13219 7.90003 8.13219C7.82316 8.13219 7.74704 8.11697 7.67608 8.0874C7.60512 8.05784 7.54071 8.01452 7.48658 7.95994L4.81949 5.29286C4.76536 5.23828 4.70095 5.19495 4.62999 5.16539C4.55903 5.13583 4.48291 5.12061 4.40604 5.12061C4.32916 5.12061 4.25305 5.13583 4.18208 5.16539C4.11112 5.19495 4.04672 5.23828 3.99258 5.29286C3.88412 5.40197 3.82324 5.54956 3.82324 5.7034C3.82324 5.85725 3.88412 6.00484 3.99258 6.11395L6.66549 8.78685C6.99305 9.114 7.43708 9.29776 7.90003 9.29776C8.36299 9.29776 8.80701 9.114 9.13458 8.78685L11.8075 6.11395C11.9159 6.00484 11.9768 5.85725 11.9768 5.7034C11.9768 5.54956 11.9159 5.40197 11.8075 5.29286Z"
            fill="#374957"
          />
        </svg>
      </div>

      <div
        className="option position-absolute start-0 border rounded p-2 z-3 bg-white"
        style={{ top: "40px", width: "max-content" }}
      >
        <div className="d-flex flex-column gap-2">
          {options?.map((item: any, index: number) => (
            <div
              key={index}
              onClick={
                item?.value === 1 && is_max_number_admin
                  ? () => { }
                  : handleChangeOption(item)
              }
              className="d-flex gap-1"
            >
              <div style={{ width: 14 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  style={{
                    marginTop: 6,
                    display: item?.value === value ? "block" : "none",
                  }}
                >
                  <g clipPath="url(#clip0_231_6458)">
                    <path
                      d="M9.29951 1.84625L3.5416 7.60375C3.50288 7.64262 3.45688 7.67346 3.40622 7.6945C3.35556 7.71554 3.30124 7.72637 3.24639 7.72637C3.19153 7.72637 3.13722 7.71554 3.08656 7.6945C3.0359 7.67346 2.98989 7.64262 2.95118 7.60375L0.724512 5.375C0.685799 5.33614 0.639794 5.3053 0.589134 5.28426C0.538475 5.26321 0.484159 5.25238 0.429303 5.25238C0.374448 5.25238 0.320132 5.26321 0.269473 5.28426C0.218813 5.3053 0.172807 5.33614 0.134095 5.375C0.0952298 5.41372 0.0643913 5.45972 0.0433489 5.51038C0.0223065 5.56104 0.0114746 5.61536 0.0114746 5.67021C0.0114746 5.72507 0.0223065 5.77938 0.0433489 5.83004C0.0643913 5.8807 0.0952298 5.92671 0.134095 5.96542L2.3616 8.1925C2.59657 8.42705 2.91501 8.55877 3.24701 8.55877C3.57901 8.55877 3.89745 8.42705 4.13243 8.1925L9.88993 2.43625C9.92873 2.39755 9.95952 2.35157 9.98052 2.30095C10.0015 2.25033 10.0123 2.19606 10.0123 2.14125C10.0123 2.08645 10.0015 2.03218 9.98052 1.98156C9.95952 1.93094 9.92873 1.88496 9.88993 1.84625C9.85122 1.80739 9.80521 1.77655 9.75455 1.75551C9.70389 1.73446 9.64958 1.72363 9.59472 1.72363C9.53987 1.72363 9.48555 1.73446 9.43489 1.75551C9.38423 1.77655 9.33823 1.80739 9.29951 1.84625Z"
                      fill="black"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_231_6458">
                      <rect width="10" height="10" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="text-start">
                <p className="m-0 fw-bold">{item?.label}</p>
                <p className="m-0">{item?.desc}</p>

                {item?.value === 1 && is_max_number_admin ? (
                  <div className="d-flex align-items-center gap-1 mt-1">
                    <img src={warningSvg} alt="" />
                    <div style={{ fontSize: "10px", color: "#FF5C46" }}>
                      <span>최대 {limit_admin}명까지 설정 가능합니다.</span> <br />
                      <span>
                        관리자 권한을 갖고있는 멤버의 권한을 먼저 변경해주세요.
                      </span>
                    </div>
                  </div>
                ) : null}
                {value !== 1 && is_max_number_desk_reporter ? (
                  <div className="d-flex align-items-center gap-1 mt-1">
                    <img src={warningSvg} alt="" />
                    <div style={{ fontSize: "10px", color: "#FF5C46" }}>
                      <span>설정가능한 멤버수를 초과하였습니다.</span> <br />
                      <span>
                        데스크/기자 권한을 갖고있는 멤버의 권한을 먼저 변경해주세요.
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const MemberInformation = ({
  id,
  refresh,
  onClose,
  refetch,
  mediaDetailDefault,
  mode,
}: {
  id: number;
  refresh: any;
  onClose: any;
  refetch?: any;
  mediaDetailDefault?: any;
  mode?: "view" | "edit";
}) => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<Array<any>>([]);

  const [openConfirm, setOpenConfirm] = useState<any>(false);
  const mediaDetail = useMemo(() => {
    return mediaDetailDefault;
  }, [mediaDetailDefault, id])
  const OPTIONS = [
    {
      value: 1,
      label: t("media.Manager"),
      desc: t("media.ManagerDesc"),
      type: [LAYOUT_TYPE.NEWS, LAYOUT_TYPE.MAGAZINE],
    },
    {
      value: 2,
      label: t("media.Desk"),
      desc: t("media.DeskDesc"),
      type: LAYOUT_TYPE.NEWS,
    },
    {
      value: 3,
      label: t("media.Reporter"),
      desc: t("media.ReporterDesc"),
      type: LAYOUT_TYPE.NEWS,
    },
    {
      value: 3,
      label: t("media.Editor_1"),
      desc: t("media.EditorDesc"),
      type: LAYOUT_TYPE.MAGAZINE,
    },
  ];

  const columns = useMemo(
    () => [
      {
        Header: t("media.MemberName"),
        accessor: "name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },

      {
        Header: t("media.Email"),
        accessor: "email",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },
      {
        Header: t("media.Contact"),
        accessor: "phone_number",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },
      {
        Header: t("media.Authority"),
        accessor: "role_id",
        thClass: "text-center",
        Cell: (cell: any) => {
          const handleOnChange = (value: number, label: string) => {
            setOpenConfirm({
              user_id: cell?.row?.original?.id,
              role_id: value,
              name: cell?.row?.original?.name,
              new_role: label,
              current_role: cell?.row?.original?.role_name,
            });
          };
          return (
            <div className="w-100 text-center">
              <SelectAuthority
                options={OPTIONS.filter((item: any) => item?.type?.includes(mediaDetail?.layout_type))}
                value={cell?.value}
                is_max_number_admin={cell?.row?.original?.is_max_number_admin}
                id={cell?.row?.original?.id}
                disabled={cell?.row?.original?.is_main_admin || mode === "view"}
                onChange={handleOnChange}
                limit_admin={cell?.row?.original?.limit_admin}
                is_max_number_desk_reporter={cell?.row?.original?.is_max_number_desk_reporter}
              />
            </div>
          );
        },
      },
      {
        Header: t("media.Department"),
        accessor: "department_name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">{cell?.value}</p>
          </div>
        ),
      },
      {
        Header: t("media.JoinDate"),
        accessor: "created_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">
              {cell?.value ? moment(cell?.value).format("YYYY.MM.DD") : ""}
            </p>
          </div>
        ),
      },
      {
        Header: t("media.LastLoginDate"),
        accessor: "last_login_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="w-100 text-center">
            <p className="m-0">
              {cell?.value ? moment(cell?.value).format("YYYY.MM.DD") : ""}
            </p>
          </div>
        ),
      },
      {
        Header: t("media.Note"),
        accessor: "note",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className="text-center">
            {!cell?.row?.original?.is_main_admin ? (
              <button
                type="button"
                onClick={() => {
                  showModal(
                    {
                      message: (
                        <div>
                          <p>
                            {" "}
                            {t("media.ConfirmUpdateRole", {
                              name: cell?.row?.original?.name,
                              permission: cell?.row?.original?.role_name,
                            })}
                          </p>
                          <p style={{ color: "#555" }}>
                            {" "}
                            {t("media.DConfirmUpdateRole")}
                          </p>
                        </div>
                      ),
                      title: t("media.Guide"),
                      submitButtonText: t("account.btn.check"),
                      cancelButtonText: t("account.btn.cancel"),
                    },
                    () => {
                      handleUpdateRoleAdmin(cell?.row?.original);
                    }
                  );
                }}
                className="btn btn-primary mx-auto fs-14"
              >
                {t("media.DelegationOfAuthority")}
              </button>
            ) : (
              <div>{t("media.MainManager")}</div>
            )}
          </div>
        ),
      },
    ],
    [id]
  );

  const getData = async (id: number) => {
    const [err, result] = await catchAsync(MediaApi.getusers(id), setLoading);
    if (result?.statusCode === 200) {
      setRows(result?.data);
    }
    if (err) {
      console.log(err);
      return;
    }
  };

  const handleCheck = async () => {
    const [err, result] = await catchAsync(
      MediaApi.updateRole(id, openConfirm)
    );
    if (err) {
      console.log(err);
      toast.error(err?.message);
      return;
    }
    toast.success(result.message);
    getData(id);
    setOpenConfirm(false);
  };

  const handleUpdateRoleAdmin = async (row: any) => {
    const [err, _] = await catchAsync(
      MediaApi.updateRole(id, {
        user_id: row?.id,
        role_id: 1,
        is_main_admin: 1,
      })
    );

    if (err) {
      toast.error(err?.message);
      return;
    }
    toast.success("success");
    getData(id);
    refetch && refetch();
    return true;
  };

  const handleSendPassword = async () => {
    const [err, _] = await catchAsync(MediaApi.sendEmailMember(id));
    if (err) {
      toast.error(err.message);
      return;
    }
    refetch && refetch();
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);


  return (
    <div className="h-100 d-flex flex-column ">
      <div className="d-flex justify-content-end mb-2">
        <button
          type="button"
          className="btn btn-primary fs-14 w-25"
          disabled={
            (mediaDetail?.migration_status_member === 1 ? false : true) ||
            mode === "view"
          }
          onClick={() => {
            showModal(
              {
                message: (
                  <div>
                    <p>
                      {t("media.SendMailConfirm", {
                        lenght: rows?.length,
                      })}
                    </p>
                  </div>
                ),
                title: t("media.Guide"),
                submitButtonText: t("account.btn.check"),
                cancelButtonText: t("account.btn.cancel"),
              },
              () => {
                return handleSendPassword();
              }
            );
          }}
        >
          {t("media.TemporaryPasswordSentByEmail")}
        </button>
      </div>
      <div className="flex-fill w-full" style={{ maxHeight: "calc(100% - 47px)" }}>
        <TableContainer
          className="custom-header-css"
          divClass="table-responsive table-card m-0 h-100"
          tableClass={`align-middle w-100`}
          theadClass="table-light"
          columns={columns}
          data={rows}
          isLoading={loading}
          isShowPagination={false}
          scrollbarOs={false}
        />
      </div>
      <div className="text-center mt-2">
        <button
          type="button"
          onClick={() => {
            onClose();
          }}
          className="btn btn-primary mx-auto fs-14 w-25"
        >
          {t("media.Check")}
        </button>
      </div>

      <ModalConfirm
        header={""}
        isOpen={!!openConfirm}
        isShowIcon={false}
        title={t("media.Check")}
        content={t("media.SubCheck", {
          name: openConfirm?.name,
          current_role: openConfirm?.current_role,
          new_role: openConfirm?.new_role,
        })}
        textButtonConfirm={t("media.Check")}
        classButtonConfirm="btn-primary"
        classIconButtonConfirm=""
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleCheck}
      />
    </div>
  );
};

export default MemberInformation;
