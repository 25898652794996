import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";

import { QueryParams } from "components/Hooks/useQueryParams";
import {
  IInquiryCustomer,
  IInquiryCustomerDetail,
  IInquiryHomePage,
  ISummary,
  ResponseInquiryList,
} from "./types/_inquiry";
const api = new APIClient();

const path = "/inquiries";

const inquiryApi = {
  getListHomePage(
    dataForm: QueryParams
  ): Promise<AxiosResponse<ResponseInquiryList<IInquiryHomePage[]>, any>> {
    const url = `${path}/homepage`;
    return api.get(url, dataForm);
  },
  getSummariesHomePage(
    params: any
  ): Promise<AxiosResponse<Array<ISummary>, any>> {
    const url = `${path}/homepage/summary`;
    return api.get(url, params);
  },
  updateReplyHomePage({
    id,
    body,
  }: {
    id: number;
    body: any;
  }): Promise<AxiosResponse<any, any>> {
    const url = `${path}/homepage/${id}/reply`;
    return api.post(url, body);
  },
  getTotalWaiting(params: any): Promise<AxiosResponse<number, any>> {
    const url = `${path}/homepage/total-waiting`;
    return api.get(url, params);
  },
  getSummaries(params: any): Promise<AxiosResponse<Array<ISummary>, any>> {
    const url = `${path}/customer/summary`;
    return api.get(url, params);
  },
  getList(
    dataForm: QueryParams
  ): Promise<AxiosResponse<ResponseInquiryList<IInquiryCustomer[]>, any>> {
    const url = `${path}/customer`;
    return api.get(url, dataForm);
  },
  getDetail(
    id: string,
    dataForm: QueryParams
  ): Promise<AxiosResponse<IInquiryCustomerDetail, any>> {
    const url = `${path}/customer/${id}`;
    return api.get(url, dataForm);
  },
  updateReply({
    id,
    body,
  }: {
    id: number;
    body: any;
  }): Promise<AxiosResponse<any, any>> {
    const url = `${path}/customer/${id}/reply`;
    return api.post(url, body, {
      headers: {
        "access-control-allow-headers": "*",
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateStatusWaiting(id: string): Promise<AxiosResponse<any, any>> {
    const url = `${path}/customer/${id}/waiting`;
    return api.patch(url, {});
  },
  updateStatusInProgress(id: string): Promise<AxiosResponse<any, any>> {
    const url = `${path}/customer/${id}/processing`;
    return api.patch(url, {});
  },
  getPhoneSummary(): Promise<
    AxiosResponse<ResponseInquiryList<IInquiryCustomer[]>, any>
  > {
    const url = `${path}/phone/summary`;
    return api.get(url);
  },
  getPhone(
    dataForm: QueryParams
  ): Promise<AxiosResponse<ResponseInquiryList<IInquiryCustomer[]>, any>> {
    const url = `${path}/phone`;
    return api.get(url, dataForm);
  },
  getSummary(): Promise<
    AxiosResponse<ResponseInquiryList<IInquiryCustomer[]>, any>
  > {
    const url = `${path}/summary`;
    return api.get(url);
  },
  createInquiriesPhone(
    body: any
  ): Promise<AxiosResponse<ResponseInquiryList<any[]>, any>> {
    const url = `${path}/phone`;
    return api.post(url, body);
  },
  updateInquiriesPhone(
    id: string,
    body: any
  ): Promise<AxiosResponse<ResponseInquiryList<any[]>, any>> {
    const url = `${path}/phone/${id}`;
    return api.put(url, body);
  },
  deleteInquiriesPhone(
    id: string
  ): Promise<AxiosResponse<ResponseInquiryList<any[]>, any>> {
    const url = `${path}/phone/${id}`;
    return api.delete(url);
  },
  getHomepageDetail(
    id: string,
    dataForm: QueryParams
  ): Promise<AxiosResponse<IInquiryCustomerDetail, any>> {
    const url = `${path}/homepage/${id}`;
    return api.get(url, dataForm);
  },
  getPhoneDetail(
    id: string,
    dataForm: QueryParams
  ): Promise<AxiosResponse<IInquiryCustomerDetail, any>> {
    const url = `${path}/phone/${id}`;
    return api.get(url, dataForm);
  },
};
export default inquiryApi;
