/* eslint-disable react-hooks/exhaustive-deps */
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";

import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import {
  TRANSFER_STATUS_OPTIONS,
  TRANSFER_DATA_TYPE_OPTIONS,
  TRANSFER_SORT_BY,
} from "helpers/constans";
import moment from "moment";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import { IPagination } from "api/types/general";
import { CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import CardSummary from "pages/Media/Components/CardSummary";
import TableContainer from "components/Common/TableContainer";
import DatePickerCustom from "components/Common/DatePickerCustom";
import ModalDetail from "./ModalDetail";
import BreadCrumb from "components/Common/BreadCrumb";
import transferApi from "api/transferApi";
import FeatherIcon from "feather-icons-react";
import HistoriesChange from "./HistoriesChange";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const TransferList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const location = useLocation();
  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  const TRANSFER_STATUS_OPTIONS_LANG = TRANSFER_STATUS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
    department: t(item?.department),
  }));

  const TRANSFER_DATA_TYPE_OPTIONS_LANG = TRANSFER_DATA_TYPE_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const SORT_BY_OPTIONS = TRANSFER_SORT_BY?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [formatSearch, setFormatSearch] = useState<Option | null>({
    label: t("transfer.status.all"),
    value: "0",
  });

  const [sortBy, setSortBy] = useState<Option | null>({
    label: t("transfer.sort_by.newest"),
    value: "DESC",
  });

  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 10,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    sort_by: "DESC",
    status: "0",
  };
  const { getQueryParams, clearQueryParams, setQueryParams } = useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [summaries, setSummaries] = useState<any>();
  const defaultRangeDate = [
    queryParams?.start_date ? moment(queryParams?.start_date || "", "Y-MM-DD").toDate() : null,
    queryParams?.end_date ? moment(queryParams?.end_date || "", "Y-MM-DD").toDate() : null,
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;
  const [isChangeKeyword, setIsChangeKeyword] = useState<boolean>(false);
  const [histories, setHistories] = useState<{ open: boolean; id: string }>({
    open: false,
    id: "",
  });

  const columns = useMemo(
    () => [
      {
        Header: t("transfer.table.number"),
        thClass: "text-center",
        Cell: (cell: any) => (
          <div
            className="text-center"
            style={{
              width: "60px",
            }}
          >
            <span>
              {pagination.total - pagination.page_size * (pagination.page - 1) - cell?.row?.index}
            </span>
          </div>
        ),
      },
      {
        Header: t("transfer.table.media_name"),
        accessor: "company_name",
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <p
              onClick={() => {
                setSelectedRow(cell?.row?.original);
              }}
              className="text-decoration-underline cursor-pointer mb-0"
              style={{
                minWidth: "100px",
              }}
            >
              {cell?.value ? cell?.value : "-"}
            </p>
          );
        },
      },
      {
        Header: t("transfer.table.contact_name"),
        accessor: "contact_name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("transfer.table.email"),
        accessor: "email",
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <p
              className="mb-0"
              style={{
                minWidth: "100px",
              }}
            >
              {cell?.value ? cell?.value : "-"}
            </p>
          );
        },
      },
      {
        Header: t("transfer.table.application_date"),
        accessor: "created_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "150px",
            }}
          >
            {moment(cell?.value).isValid()
              ? moment(cell?.value).format("YYYY.MM.DD HH:mm:ss")
              : "-"}
          </p>
        ),
      },
      {
        Header: (
          <div className="d-flex gap-2 align-items-center justify-content-center">
            <p className="mb-0">{t("transfer.table.status")}</p>
            <div className="position-relative">
              <div
                onMouseOver={() => {
                  setStatusOpen(true);
                }}
                onMouseLeave={() => setStatusOpen(false)}
              >
                <FeatherIcon icon="info" className="icon-dual" style={{ width: "16px" }} />
              </div>
              {statusOpen && (
                <div
                  className="position-absolute rounded border border-dark p-2 z-3 bg-white mt-1"
                  style={{ minWidth: "200px", left: "-90px" }}
                >
                  <p className="text-start mb-0">{t("transfer.status_list")}</p>
                  <div className="d-flex justify-content-between">
                    <p className="mb-0">{t("transfer.category")}</p>
                    <p className="mb-0">{t("transfer.responsible_department")}</p>
                  </div>

                  {TRANSFER_STATUS_OPTIONS_LANG.filter((item) => item.value != 0).map(
                    ({ label, department }) => (
                      <div className="d-flex justify-content-between" key={label}>
                        <p className="mb-0 fw-normal" style={{ fontSize: 10 }}>
                          {t(label)}
                        </p>
                        <p className="mb-0 fw-normal" style={{ fontSize: 10 }}>
                          {t(department)}
                        </p>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        ),
        accessor: "status",
        thClass: "text-center",
        Cell: (cell: any) => {
          const status = TRANSFER_STATUS_OPTIONS_LANG.find((item) => item.value == cell?.value);
          const options = TRANSFER_STATUS_OPTIONS_LANG.filter((item) => item.value != 0);
          return (
            <div
              style={{
                minWidth: "120px",
              }}
            >
              <DropdownOption
                name="status"
                dataList={options || []}
                className="search-filter-category-type"
                classNamePrefix="name-prefix"
                initialValue={status || null}
                onChangeSelect={(value: Option) => {
                  changeStatus(cell?.row?.original?.id, Number(value?.value));
                }}
              />
            </div>
          );
        },
      },
      {
        Header: t("transfer.table.update_date"),
        accessor: "updated_at",
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <p
              className="mb-0"
              style={{
                minWidth: "150px",
              }}
            >
              {cell?.row?.original?.managed_by && moment(cell.value).isValid()
                ? moment(cell.value).format("YYYY.MM.DD HH:mm:ss")
                : "-"}
            </p>
          );
        },
      },
      {
        Header: t("transfer.table.sales_representative"),
        accessor: "managed_by",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value?.name ? cell?.value?.name : "-"}
          </p>
        ),
      },
      {
        Header: t("transfer.change_history"),
        accessor: "id",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0 text-decoration-underline cursor-pointer "
            style={{
              minWidth: "100px",
            }}
            onClick={() => viewHistory(cell?.value)}
          >
            {t("transfer.view_details")}
          </p>
        ),
      },
    ],
    [i18n?.language, userPermissions, pagination, statusOpen]
  );

  const viewHistory = (id: string) => {
    setHistories({
      open: true,
      id: id,
    });
  };

  const refresh = () => {
    getList();
    getSummaries();
  };

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      page: 1,
      search: isChangeKeyword ? keyword : queryParams?.search,
      status: formatSearch?.value ? formatSearch?.value : queryParams?.status,
      sort_by: sortBy?.value ? sortBy?.value : queryParams?.sort_by,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
    });
  };

  const getSummaries = async () => {
    const [err, result] = await catchAsync(transferApi.getSummaries({}), setLoadingSummaries);
    if (err) {
      toast.error(err);
      return;
    }
    setSummaries(result?.data);
  };

  const getList = async () => {
    setLoading(true);
    const requestBody = {
      ...queryParams,
      status: queryParams?.status || "0",
    };

    const [err, result] = await catchAsync(transferApi.getList(requestBody), setLoading);
    if (err) {
      toast.error(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const changeStatus = async (id: number, status: number) => {
    if (!id) {
      // setLoadingStatus(false);
      return;
    }
    const [err, result] = await catchAsync(
      transferApi.updateStatus({
        id: id,
        body: {
          status: status,
        },
      })
      // setLoadingStatus
    );

    if (result?.statusCode === 200) {
      toast("success", CONFIG_OPTION_TOAST_NORMAL);
      refresh();
    }
    if (err) {
      toast.error(err);
      return;
    }
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const onCloseClick = () => {
    setSelectedRow({});
    refresh();
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setFormatSearch({
      label: t("transfer.status.all"),
      value: "0",
    });
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const renderSummary = [
    {
      title: "transfer.status.import_request_media",
      value: summaries?.["5"] || 0,
      isLoading: loadingSummaries,
    },
    {
      title: "transfer.status.import_in_progress_completed",
      isLoading: loadingSummaries,
      isDouble: true,
      value: summaries?.["6"] || 0,
      total: summaries?.["7"] || 0,
    },
    {
      title: "transfer.status.data_review_in_progress_completed",
      isLoading: loadingSummaries,
      isDouble: true,
      value: summaries?.["8"] || 0,
      total: summaries?.["9"] || 0,
    },
    {
      title: "transfer.status.modification_needed_completed",
      isLoading: loadingSummaries,
      isDouble: true,
      value: summaries?.["10"] || 0,
      total: summaries?.["11"] || 0,
    },
    {
      title: "transfer.status.review_completed",
      value: summaries?.["12"] || 0,
      isLoading: loadingSummaries,
    },
    {
      title: "transfer.status.transfer_completed",
      value: summaries?.["13"] || 0,
      isLoading: loadingSummaries,
    },
    {
      title: "transfer.status.setup_domain_CDN_completed",
      value: summaries?.["14"] || 0,
      isLoading: loadingSummaries,
    },
    {
      title: "transfer.status.final_review_completed",
      value: summaries?.["15"] || 0,
      isLoading: loadingSummaries,
    },
    {
      title: "transfer.status.live",
      value: summaries?.["16"] || 0,
      isLoading: loadingSummaries,
    },
  ];

  useEffect(() => {
    searchData();
  }, [formatSearch, sortBy]);

  useEffect(() => {
    getList();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location?.search]);

  useEffect(() => {
    // document.title = `Super Admin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const findSortBy = SORT_BY_OPTIONS?.find((item) => item.value == sortBy?.value);
    const newSortBy = {
      value: findSortBy?.value,
      label: findSortBy ? t(findSortBy.label) : "",
    };
    setSortBy(newSortBy);
  }, [i18n?.language]);

  useEffect(() => {
    getSummaries();
  }, []);

  return (
    <React.Fragment>
      {histories?.open && (
        <HistoriesChange
          open={histories?.open}
          id={histories?.id}
          onClose={() => setHistories({ open: false, id: "" })}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("transfer.menu_title")} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <div className="d-flex flex-column flex-md-row gap-x-0 gap-y-2 align-items-start align-items-lg-end mb-2 flex-wrap">
                    {renderSummary.map(({ title, value, isLoading, total, isDouble }) => (
                      <div
                        style={{
                          minWidth: "200px",
                        }}
                        key={title}
                      >
                        <CardSummary
                          title={title}
                          value={value}
                          isLoading={isLoading}
                          isDouble={isDouble}
                          total={total}
                          className="mb-2"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2">
                    <div className="w-100">
                      <div className="align-items-end">
                        <Row>
                          <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-0 mb-2 mb-md-0">
                            <div
                              className="d-flex align-items-center date-picker-wrapper-custom"
                              style={{ gap: "40px" }}
                            >
                              <div className="fw-bold text-nowrap">{t("inquiry.period")}</div>
                              <div style={{ width: "300px" }}>
                                <DatePickerCustom
                                  placeholder={`${t("A From Range")} - ${t("A To Range")}`}
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  disabled={false}
                                  isClearable={true}
                                  onChangePicker={handleChangePicker}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mb-2 mt-2">
                          <Col sm={12} lg={12} className="mt-2">
                            <div className="d-flex align-items-center gap-3">
                              <div
                                className="d-flex align-items-center date-picker-wrapper-custom"
                                style={{ gap: "40px" }}
                              >
                                <div className="fw-bold text-nowrap">
                                  {t("transfer.table.status")}
                                </div>
                                <div style={{ width: "300px" }}>
                                  <DropdownOption
                                    name="status"
                                    dataList={TRANSFER_STATUS_OPTIONS_LANG || []}
                                    className="search-filter-category-type"
                                    classNamePrefix="name-prefix"
                                    initialValue={
                                      TRANSFER_STATUS_OPTIONS_LANG?.find(
                                        (item: any) => item.value == formatSearch?.value
                                      ) || {
                                        label: t("transfer.status.all"),
                                        value: "0",
                                      }
                                    }
                                    onChangeSelect={(event: any) => {
                                      const selected = TRANSFER_STATUS_OPTIONS_LANG?.find(
                                        (item: any) => item.value == event?.value
                                      );
                                      selected && setFormatSearch(selected);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center mt-0 mt-md-2">
                          <Col sm={12} md={12} lg={4} className="mt-3 mt-md-2">
                            <div className="d-flex align-items-center " style={{ gap: "40px" }}>
                              <div className="fw-bold text-nowrap">{t("inquiry.search")}</div>
                              <Input
                                type="text"
                                className="form-control search"
                                placeholder={`${t("inquiry.PlaceholderSearch")}`}
                                value={keyword}
                                onChange={(e) => {
                                  setIsChangeKeyword(true);
                                  setKeyword(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    searchData();
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col
                            sm={12}
                            md={12}
                            lg={3}
                            className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2"
                          >
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={loading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>
                                {t("Button Search")}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>
                                {t("Button Reset")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    {/* <Nav
                      className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0"
                      role="tablist"
                    >
                      {TRANSFER_STATUS_OPTIONS_LANG.map((item, index) => (
                        <NavItem key={index}>
                          <NavLink
                            className={`cursor-pointer ${classnames({
                              active: formatSearch?.value == item?.value,
                            })}`}
                            onClick={() => {
                              setFormatSearch(item);
                            }}
                          >
                            {t(item?.label)}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav> */}
                    <LabelWrapper isShow={false} className="w-25">
                      <DropdownOption
                        name="sort_by"
                        dataList={SORT_BY_OPTIONS || []}
                        className="search-filter-category-type"
                        classNamePrefix="name-prefix"
                        initialValue={sortBy || null}
                        onChangeSelect={(value: Option) => setSortBy(value)}
                      />
                    </LabelWrapper>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle text-center"
                      theadClass="table-light text-center"
                      columns={columns}
                      data={rows || []}
                      customPageSize={pagination?.page_size}
                      customPageIndex={pagination?.page - 1}
                      totalRecords={pagination?.total}
                      customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      isLoading={loading}
                      minHeight="300px"
                      messageEmpty={t("transfer.no_data")}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {selectedRow?.id && (
        <ModalDetail selectedRow={selectedRow} onCloseClick={onCloseClick} refresh={refresh} />
      )}
    </React.Fragment>
  );
};

export default TransferList;
