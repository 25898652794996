import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Spinner } from "reactstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
const CardSummary = ({
  isLoading,
  value,
  total,
  title,
  width = "200px",
  isDouble = false,
  className = ""
}: {
  isLoading: boolean;
  value: number;
  total?: number;
  title: string;
  width?: string;
  isDouble?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();
  return (
    <Card
      className={`card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0 d-none d-md-block ${className}`}
      style={{ width }}
    >
      <CardBody>
        <div className="d-flex align-items-center ">
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t(title)}</p>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
          <h4 className="fs-22 fw-semibold ff-secondary mb-0">
            <span className="counter-value text-primary">
              {isLoading ? (
                <Spinner size="sm"></Spinner>
              ) : isDouble ? (
                <div className="d-flex align-items-center gap-1">
                  <CountUp start={0} end={value || 0} duration={1} />
                  <p className="mb-0 fs-5">/</p>
                  <CountUp start={0} end={total || 0} duration={1} />
                </div>
              ) : (
                <CountUp start={0} end={value || 0} duration={1} />
              )}
            </span>
          </h4>
        </div>
      </CardBody>
    </Card>
  );
};

export default CardSummary;
