/* eslint-disable react-hooks/exhaustive-deps */
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalBody } from "reactstrap";

import { useRole } from "components/Hooks/UserHooks";
import { TRANSFER_STATUS_OPTIONS } from "helpers/constans";
import moment from "moment";
import { catchAsync } from "helpers/general";
import { IPagination } from "api/types/general";
import TableContainer from "components/Common/TableContainer";
import transferApi from "api/transferApi";

interface Props {
  open: boolean;
  id: string;
  onClose: () => void;
}

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const HistoriesChange = ({ open, id, onClose }: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const TRANSFER_STATUS_OPTIONS_LANG = TRANSFER_STATUS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
    department: t(item?.department),
  }));

  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 10,
  };
  const [queryParams, setQueryParams] = useState<any>(defaultParams);
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);

  const columns = useMemo(
    () => [
      {
        Header: t("transfer.table.status"),
        accessor: "status",
        thClass: "text-center",
        Cell: (cell: any) => {
          const status = TRANSFER_STATUS_OPTIONS_LANG.find((item) => item.value == cell?.value);
          return (
            <div
              style={{
                minWidth: "120px",
              }}
            >
              <p>{status?.label}</p>
            </div>
          );
        },
      },
      {
        Header: t("transfer.table.update_date"),
        accessor: "updated_at",
        thClass: "text-center",
        Cell: (cell: any) => {
          return (
            <p
              className="mb-0"
              style={{
                minWidth: "150px",
              }}
            >
              {cell?.row?.original?.managed_by && moment(cell.value).isValid()
                ? moment(cell.value).format("YYYY.MM.DD HH:mm:ss")
                : "-"}
            </p>
          );
        },
      },
      {
        Header: t("transfer.table.sales_representative"),
        accessor: "managed_by",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className="mb-0"
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value?.name ? cell?.value?.name : "-"}
          </p>
        ),
      },
    ],
    [i18n?.language, userPermissions, pagination]
  );

  const getList = async () => {
    setLoading(true);
    const requestBody = {
      ...queryParams,
      status: queryParams?.status || "0",
    };

    const [err, result] = await catchAsync(transferApi.getHistory(requestBody, id), setLoading);
    if (err) {
      toast.error(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  useEffect(() => {
    if(id) {getList()};
  }, [id, queryParams]);

  return (
    <Modal
      isOpen={open}
      centered={true}
      scrollable={true}
      style={{ maxWidth: "1000px", width: "50%", maxHeight: "80dvh" }}
    >
      <ModalBody
        className=""
        style={{
          paddingTop: "0",
        }}
      >
        <div
          style={{
            maxHeight: "80dvh",
          }}
        >
                    <div
            className="d-relative"
            style={{
              position: "sticky",
              top: "0",
              background: "white",
              paddingTop: "20px",
              zIndex: 1,
            }}
          >
            <h5 className="text-center w-100 pb-2">
              {t("transfer.change_history")}
            </h5>
            <div
              className="position-absolute cursor-pointer"
              style={{
                top: "12px",
                right: "0px",
              }}
            >
              <button
                onClick={onClose}
                type="button"
                className="btn-close"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="pb-4">
          <TableContainer
            // className="custom-header-css"
            // divClass="table-responsive table-card"
            tableClass="align-middle text-center"
            // theadClass="table-light text-center"
            columns={columns}
            data={rows || []}
            customPageSize={pagination?.page_size}
            customPageIndex={pagination?.page - 1}
            totalRecords={pagination?.total}
            customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
            handleChangePage={handleChangePage}
            manualSorting={true}
            isLoading={loading}
          />
          </div>
        </div>
        <ToastContainer closeButton={false} limit={1} />
      </ModalBody>
    </Modal>
  );
};

export default HistoriesChange;
